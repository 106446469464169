<template>
  <div>
    <Banner :title="banner.title" :text="banner.text" :imgsrc="banner.img"/>
    <v-container 
      class="py-10 banner-bg"
      fluid
    >
      <v-row 
        class="px-0 pt-md-3 mb-md-2" 
      >
        <v-col
          cols="12"
          md="6"
          class="px-8 px-md-15 d-flex align-center"
          v-scroll-x
        >
          <div>
            <p>
              PITAH Magazine is the official newsletter of SMARTER Brunei which is an NGO society formed in the 9th September 2001 and run by parents and family members of inidividuals with Autism.
            </p>
            <p>
              The origin of the name, PITAH is directly derived from the Brunei Malay word which is used to describe a "talkative" child. One of the main hopes and aspiration of SMARTER Brunei is that our students can attain good communication skills. It would be a dream come true for our children to be called PITAH.
            </p>
            <p>
              PITAH magazine content includes internal news of SMARTER Brunei, both past and past and upcoming SMARTER events - training, social, fund raising, conferences, etc., Parents' and Teachers' personal experience, discussion and perspective on Autism, it's therapy and parental related issues. The objective of PITAH is to produce a magazine with information dedicated to the activities of SMARTER Brunei, Autism and Autism associated therapy.
            </p>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="px-0"
          v-scroll-x
        >
          <v-img
            :src="`https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${$env}/Carousel/carousel_2.jpg`"
            height="100%"
            :class="$vuetify.breakpoint.smAndDown ? '' : 'rounded-l-lg'"
          >
          </v-img>
        </v-col>
        <v-col
          cols="12"
          class="px-8 px-md-15 mt-10"
          v-scroll-x
        >
          <v-tabs
            v-model="tab"
            background-color="primary"
            show-arrows
            grow
            slider-size="4"
            dark
            active-class="tabs"
          >
            <v-tab
              v-for="item in editions"
              :key="item.edition"
              class="white--text"
            >
              {{ item.edition }}
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item
              v-for="item in editions"
              :key="item.edition"
            >
              <v-card color="transparent">
                <v-img
                  :src="item.coverImage"
                  height="100%"
                >
                </v-img>
                <v-card-text v-html="item.description"></v-card-text>
                <v-card-actions v-if="item.pdf_url">
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="openPdf(item.pdf_url)"
                  >View full PDF <v-icon class="pl-2">mdi-open-in-new</v-icon></v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
    </v-container>
    <Footer/>
  </div>
</template>
  
<script>
import Banner from '../components/Banner.vue';
import Footer from '../components/Footer.vue';
import { editions } from '../data/e-pitah'

export default {
  name: 'Backstory',
  components: {
    Banner,
    Footer
  },
  data () {
    return {
      banner: {
        title: 'E-PITAH',
        text: 'The official newsletter of SMARTER Brunei',
        img: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${this.$env}/Carousel/carousel_1.jpg`
      },
      tab: null,
      editions: editions
    }
  },
  methods: {
    openPdf(url) {
      window.open(url, '_blank')
    }
  }
}
</script>
