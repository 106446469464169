<template>
  <v-img
    :src="imgsrc"
    :height="height"
    :max-height="maxHeight"
    :contain="contain"
    :lazy-src="`https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${$env}/placeholder.jpeg`"
  >
    <template v-slot:placeholder>
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <v-progress-circular
          indeterminate
          color="grey lighten-5"
        ></v-progress-circular>
      </v-row>
    </template>
  </v-img>
</template>

<script>
export default {
  props: {
    imgsrc: String,
    height: {
      default: '',
      type: String
    },
    maxHeight: {
      default: '',
      type: String
    },
    contain: {
      default: false,
      type: Boolean
    }
  }
}
</script>