<template>
  <div>
    <Banner :title="banner.title" :text="banner.text" :imgsrc="banner.img"/>
    <Backstories/>
    <Message/>
    <Footer/>
  </div>
</template>

<script>
import Backstories from '../components/backstory/Backstories.vue';
import Message from '../components/backstory/Message.vue';
import Banner from '../components/Banner.vue';
import Footer from '../components/Footer.vue';

export default {
  name: 'Backstory',
  components: {
    Banner,
    Backstories,
    Message,
    Footer
  },
  data () {
    return {
      banner: {
        title: 'Backstory',
        text: 'From humble beginnings, a mission to bring light to Autism.',
        img: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${this.$env}/Carousel/carousel_3.jpg` }
    }
  }
}
</script>