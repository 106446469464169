<template>
  <div>
    <Banner :title="banner.title" :text="banner.text" :imgsrc="banner.img"/>
    <v-container 
      class="px-6 py-12 pa-lg-16 banner-bg"
      fluid
    >
      <v-row 
        class="px-md-8 px-lg-16 pt-md-3 mb-md-2" 
        justify="center"
      >
        <v-col 
          cols="12" 
          v-scroll-x
        >
          <router-link to="/news" class="link">
            <span>News Page</span>
          </router-link>
          /
          <span 
            class="primary--text" 
          >
            {{ data.title }}
          </span>
        </v-col>
        <v-col
          cols="12"
          v-scroll-x
        >
          <Picture :imgsrc="data.img"></Picture>
        </v-col>
        <v-col 
          cols="12" 
          align="center"
        >
          <h2
            class="primary--text"
            v-scroll-x  
          >
            {{ data.title }}
          </h2>
        </v-col>
        <v-col
          cols="12"
        >
          <p 
            v-html="data.desc"
            v-scroll-x
          >
          </p>
        </v-col>
        <v-col
          cols="12"
          class="text-right primary--text"
        >
          <router-link to="/news" class="link">
            <span>Back</span>
          </router-link>
        </v-col>
      </v-row>
    </v-container>
    <Footer/>
  </div>
</template>

<script>
import Banner from '../components/Banner.vue';
import Footer from '../components/Footer.vue';
import Picture from '../components/Picture.vue';
import { news } from '../data/news'

export default {
  name: 'Backstory',
  components: {
    Banner,
    Footer,
    Picture
  },
  data () {
    return {
      banner: {
        title: 'Latest News',
        text: 'Be caught up with all SMARTER Brunei news and events near you!',
        img: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${this.$env}/Carousel/carousel_5.jpg`
      },
      news: news,
      data: {}
    }
  },
  methods: {
    getCurrentNews (id) {
      return this.news.find(obj => obj.id == id)
    }
  },
  created () {
    this.data = this.getCurrentNews(this.$route.params.id)
  }
}
</script>

<style scoped lang="scss">
  .banner-bg {
    background-position: right;
  }

  .link {
    color: inherit;
  }

  .link:hover {
    text-decoration: underline;
  }
</style>
