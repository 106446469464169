<template>
  <v-container fluid class="pa-0">
    <v-img 
      :src="imgsrc"
      :height="$vuetify.breakpoint.smAndDown ? 'auto' : 550"
      :aspect-ratio="101/70"
      class="banner-img white--text text-center"
    >
      <div class="layer">
        <div 
          :class="[ $vuetify.breakpoint.smAndDown ? 'captionSm' : 'caption' ]"
          v-scroll-x
        >
          <p 
            class="font-weight-black"
            :class="[ $vuetify.breakpoint.smAndDown ? 'text-h4' : 'text-h3' ]"
          >
            {{ title }}
          </p>
          <p class="text-subtitle-1">
            {{ text }}
          </p>
        </div>
      </div>
    </v-img>
  </v-container>
</template>

<script>
export default {
  props: {
    title: String,
    text: String,
    imgsrc: String
  }
}
</script>

<style scoped lang="scss">
.banner-img {
  position: relative;
}

.layer {
  position: absolute;
  background-color: rgba(0, 105, 147, .5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.caption {
  position: absolute;
  width: 100%;
  top: 40%;
}

.captionSm {
  position: absolute;
  width: 100%;
  top: 30%;
}
</style>