<template>
  <div>
    <Banner :title="banner.title" :text="banner.text" :imgsrc="banner.img"/>
    <v-container 
      class="px-6 py-12 pa-lg-16 banner-bg"
      fluid
      id="news"
    >
      <v-row v-if="news.length == 0" align="center" justify="center" style="min-height: 300px;">
        <p>There are currently no new articles right now.</p>
      </v-row>
      <v-row v-else>
        <v-col
          cols="12"
          sm="6"
          md="4"
          v-for="(item, index) in pagedNews"
          :key="index"
          class="pb-8 pb-lg-16"
          v-scroll-x
        >
          <v-card>
            <v-row>
              <v-col 
                cols="3"
                sm="12"
                lg="4"
                class="py-0"
                :class="$vuetify.breakpoint.sm ? '' : 'pr-0'"
              >
                <Picture :imgsrc="item.img" height="100%"></Picture>
              </v-col>
              <v-col
                class="py-0"
                :class="$vuetify.breakpoint.sm ? '' : 'pl-0'"
              >
                <v-card-title 
                  class="primary--text font-weight-bold"
                  :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1' : ''"
                >
                  {{ item.title }}
                </v-card-title>
                <v-card-subtitle class="primary--text">{{ item.author }}</v-card-subtitle>
                <v-card-text v-if="$vuetify.breakpoint.smAndDown">{{ item.content.substring(0, 50) + '..' }}</v-card-text>
                <v-card-text v-else>{{ item.content }}</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <router-link 
                    :to="{ 
                      name: 'SingleNews', 
                      params: { id: `${item.id}` } 
                    }"
                  >
                    <v-btn
                      text
                      color="primary"
                      class="v-buttons"
                    >
                      Read More
                    </v-btn> 
                  </router-link>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col
          cols="12"
        >
          <v-pagination
            v-model="page"
            :length="numPages"
            @input="$vuetify.goTo('#news')"
          >
          </v-pagination>
        </v-col>
      </v-row>
    </v-container>
    <Footer/>
  </div>
</template>

<script>
import Banner from '../components/Banner.vue';
import Footer from '../components/Footer.vue';
import Picture from '../components/Picture.vue';
import { news } from '../data/news'

export default {
  name: 'Backstory',
  components: {
    Banner,
    Footer,
    Picture
  },
  data () {
    return {
      banner: {
        title: 'Latest News',
        text: 'Be caught up with all SMARTER Brunei news and events near you!',
        img: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${this.$env}/Carousel/carousel_4.jpg`
      },
      news: news,
      page: 1,
      pageSize: 12
    }
  },
  computed: {
    numPages () {
      return Math.ceil(this.news.length / this.pageSize);
    },
    pagedNews () {
      return this.news.slice((this.page - 1) * this.pageSize, this.page * this.pageSize)
    }
  }
}
</script>

<style scoped lang="scss">
  .banner-bg {
    background-position: right;
  }

  .v-buttons:focus::before {
    opacity: 0 !important;
  }
</style>
