<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center text-center pa-15 primary white--text checkered-bg"
      >
        <div>
          <h3>We are the</h3>
          <h2 class="mb-10">Society for Management of Autism Related issues in Training, Education and Resources</h2>
          <p>
            It is a FAMILY SUPPORT ORGANIZATION run by Parents and Family members EXCLUSIVELY FOR AUTISM from CHILDHOOD TO OLD AGE in a Seamless Journey towards Interdependence.
          </p>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0"
      >
        <Carousel/>
      </v-col>
    </v-row>
    <Content/>
    <Testimonials/>
    <Centres/>
    <Footer/>
  </div>
</template>

<script>
import Carousel from '../components/Carousel.vue';
import Centres from '../components/Centres.vue';
import Content from '../components/Content.vue';
import Footer from '../components/Footer.vue';
import Testimonials from '../components/Testimonials.vue';

export default {
  name: 'Home',
  components: {
    Carousel,
    Content,
    Testimonials,
    Centres,
    Footer
  }
}
</script>
