<template>
  <div class="checkered-bg pa-8 pa-sm-15">
    <h1 class="white--text">
      Our Centres
    </h1>
    <v-row>
      <v-col 
        cols="12" 
        md="6"
        v-for="(centre, index) in centres"
        :key="index"
        v-scroll-x
      >
        <v-card :min-height="$vuetify.breakpoint.smAndDown ? 300 : 600">
          <Picture :imgsrc="centre.img" height="300"></Picture>
          <v-card-title class="primary--text">
            {{ centre.name }}
          </v-card-title>
          <v-card-subtitle class="primary--text">
            <v-icon color="primary">
              mdi-map-marker
            </v-icon>
            {{ centre.location }}     
          </v-card-subtitle>
          <p class="px-5">
            {{ centre.text }}
          </p>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog[index]">
              <template v-slot:activator="{ on }">
                <v-btn
                  text
                  color="primary"
                  v-on="on"
                  class="v-buttons"
                >
                  Read More
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="primary--text mb-sm-5 text-subtitle-1 text-sm-h6">
                  {{ centre.name }}
                  <v-spacer></v-spacer>
                  <v-btn
                    icon
                    @click="closedialog(index)"
                    color="primary"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-title>
                <v-card-text>
                  <GmapMap
                    :center="{lat: centre.lat, lng: centre.lng}"
                    :zoom="15"
                    style="height: 300px"
                    class="mb-5"
                  >
                    <GmapMarker
                      :position="{lat: centre.lat, lng: centre.lng}"
                      :clickable="true"
                      :draggable="true"
                      @click="center={lat: centre.lat, lng: centre.lng}"
                    >
                    </GmapMarker>
                  </GmapMap>
                  <div v-for="(item, index) in centre.full" :key="index">
                    <p v-html="item.html"></p>
                    <GmapMap
                      v-if="centre.addon_maps"
                      :center="{lat: centre.addon_maps.lat, lng: centre.addon_maps.lng}"
                      :zoom="15"
                      style="height: 300px"
                      class="mb-5"
                    >
                      <GmapMarker
                        :position="{lat: centre.addon_maps.lat, lng: centre.addon_maps.lng}"
                        :clickable="true"
                        :draggable="true"
                        @click="center={lat: centre.addon_maps.lat, lng: centre.addon_maps.lng}"
                      >
                      </GmapMarker>
                    </GmapMap>
                    <v-row>
                      <v-col
                        cols="12"
                        :md="item.images.length % 2 == 0 ? 6 : 4"
                        v-for="image in item.images"
                        :key="image"
                        class="ma-auto"
                      >
                        <Picture :imgsrc="image" height="auto" maxHeight="300" contain></Picture>
                      </v-col>
                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { centres, centresDialog } from '../data/centres'
import Picture from './Picture.vue'

export default {
  components: {
    Picture
  },
  data () {
    return {
      dialog: centresDialog,
      centres: centres
    }
  },
  methods: {
    closedialog (index) {
      this.$set(this.dialog, index, false)
    }
  }
}
</script>

<style scoped lang="scss">
  .v-buttons:focus::before {
    opacity: 0 !important;
  }
</style>
