<template>
  <v-carousel :height="$vuetify.breakpoint.smAndDown ? '350px' : '80vh'" hide-delimiters>
    <v-carousel-item
      v-for="(item, i) in items"
      :key="i"
      :src="item.src"
      :lazy-src="`https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${$env}/placeholder.jpeg`"
      v-scroll-x
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
import { carouselData } from '../data/carousel'

export default {
  name: 'Carousel',
  data () {
    return {
      items: carouselData
    }
  }
}
</script>
