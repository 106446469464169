<template>
  <div>
    <v-navigation-drawer 
      v-model="drawer"
      temporary 
      app 
      right 
      color="primary"
      :width="$vuetify.breakpoint.xs ? '60%' : 256"
    >
      <v-list 
        dense 
        class="mt-5 text-right"
      >
        <v-list-item 
          v-for="item in navbar" 
          :key="item.name" 
          :to="item.link"
        >
          <v-list-item-content>
            <v-list-item-title class="subtitle-1 white--text">
              <p class="mb-0">{{ item.name }}</p>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar 
      app 
      class="px-sm-8 pt-2 app-bar"
    >
      <router-link to="/">
        <div class="d-flex align-center">
          <v-img
            max-width="115"
            contain
            class="mr-3"
            :src="require('@/assets/logo.png')"
          ></v-img>
          <v-app-bar-title
            v-if="$vuetify.breakpoint.smAndUp"
            class="title"
          >
            <div class="primary--text font-weight-bold">
              SMARTER Brunei
            </div>
          </v-app-bar-title>
        </div>
      </router-link>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon 
        @click.stop="drawer = !drawer" 
        v-if="$vuetify.breakpoint.mobile"
      >
        <v-btn depressed>
          <v-icon color="primary">
            mdi-menu
          </v-icon>
        </v-btn>
      </v-app-bar-nav-icon>
      <div v-else>
        <router-link 
          v-for="item in navbar" 
          :to="item.link" 
          :key="item.name"
        >
          <v-btn 
            text
          >
            {{ item.name }}
          </v-btn>
        </router-link>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import { navbar } from '../data/navbar'

export default {
  name: 'NavBar',
  data () {
    return {
      drawer: null,
      navbar: navbar
    }
  }
}
</script>

<style lang="scss">
  .title {
    cursor: pointer;
  }

  .app-bar {
    height: 80px !important;
  }
</style>