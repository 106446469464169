import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import * as VueGoogleMaps from 'vue2-google-maps'
import './styles.scss'

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_MAP_KEY,
    libraries: 'places',
  }
})

Vue.prototype.$env = process.env.VUE_APP_ENV

Vue.directive('scroll-x', {
  inViewport (el) {
    let rect = el.getBoundingClientRect()
    return !(rect.bottom < 0 || rect.right < 0 || 
             rect.left > window.innerWidth ||
             rect.top > window.innerHeight)
  },
  
  bind(el, binding) {
    el.classList.add('before-enter')
    el.onScroll = function() {
      if (binding.def.inViewport(el)) {
        el.classList.add('enter')
        el.classList.remove('before-enter')
        binding.def.unbind(el, binding)        
      }
    }
    document.addEventListener('scroll', el.onScroll)
  },
  
  inserted(el) {
    el.onScroll()  
  },
  
  unbind(el) {    
    document.removeEventListener('scroll', el.onScroll)
    delete el.onScroll
  }  
})

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
