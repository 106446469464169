<template>
  <div>
    <Banner :title="banner.title" :text="banner.text" :imgsrc="banner.img"/>
    <div class="banner-bg">
      <v-timeline :dense="$vuetify.breakpoint.smAndDown" class="py-10 pr-8 px-md-8">
        <v-timeline-item
          v-for="(project, index) in projects"
          :key="index"
          color="primary"
          fill-dot
          class="d-flex align-center"
        >
          <v-carousel 
            :height="height[index]"
            slot="opposite" 
            class="rounded-lg" 
            :show-arrows="project.events[panels[index]].imgs.length > 1"
            hide-delimiters 
          >
            <v-carousel-item
              v-for="(img, i) in project.events[panels[index]].imgs"
              :key="i"
              :src="img"
              :lazy-src="`https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${$env}/placeholder.jpeg`"
              v-scroll-x
              @load="setHeight(index)"
            >
              <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
              </template>
            </v-carousel-item>
          </v-carousel>
          <h2
            class="primary--text mb-3"
            :class="[($vuetify.breakpoint.mdAndUp && index % 2 != 0) ? 'text-right' : '']"
            v-scroll-x
          >
            {{ project.year }}
          </h2>
          <v-expansion-panels 
            mandatory 
            v-scroll-x 
            v-model="panels[index]"
          >
            <v-expansion-panel
              v-for="(event, i) in project.events"
              :key="i"
            >
              <v-expansion-panel-header class="primary--text">
                {{ event.title }}
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="$vuetify.breakpoint.smAndDown">
                <v-carousel 
                  height="auto"  
                  :show-arrows="event.imgs.length > 1"
                  hide-delimiters 
                >
                  <v-carousel-item
                    v-for="(img, i) in event.imgs"
                    :key="i"
                    :src="img"
                    :lazy-src="`https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${$env}/placeholder.jpeg`"
                    v-scroll-x
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-carousel-item>
                </v-carousel>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-timeline-item>
      </v-timeline>
    </div>
    <Footer/>
  </div>
</template>

<script>
import Banner from '../components/Banner.vue';
import Footer from '../components/Footer.vue';
import { projects } from '../data/projects'

export default {
  name: 'Backstory',
  components: {
    Banner,
    Footer
  },
  data () {
    return {
      banner: {
        title: 'Our Community Projects',
        text: 'Here are our past events and projects throughout the years',
        img: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${this.$env}/Projects/projects1.jpeg`
      },
      projects: projects,
      panels: [ '0', '0', '0', '0' ],
      height: [ '500', '500', '500', '500']
    }
  },
  methods: {
    setHeight (index) {
      this.height[index] = 'auto'
    }
  }
}
</script>

<style scoped lang="scss">
  .banner-bg {
    background-position: right;
    background-size: cover;
  }

  .theme--light.v-timeline:before {
    background: #006993;
    width: 5px;
  }

  .v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse)::before {
    left: calc(50% - 2.5px);
  }

  .v-application--is-ltr {
    .v-timeline--dense:not(.v-timeline--reverse)::before {
      left: calc(48px - 2.5px);
    }
  }

  .v-expansion-panel-header {
    line-height: 25px;
  }
  .v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }

</style>
