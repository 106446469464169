const env = process.env.VUE_APP_ENV
const contentData =
  [
    {
      src: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Content/content_1.jpg`,
      header: 'As a safe space for people with Autism',
      points: [
        'To PROTECT all individual member with Autism Spectrum Disorder (ASD) and their family members from BEING TAKEN ADVANTAGE OF, ABUSED AND/OR EXPLOITED FOR MONETARY GAINS from individuals, groups of people, institutions, companies, corporate, business entities, the press or otherwise.',
        'To SUPPORT the individual member with ASD and their family members in the form of Social, Training, Education and psychological in short S.T.E.P.',
        'To PROVIDE the appropriate Knowledge and Information of ASD to every member of “the Society”, So as to assist them in making the appropriate and informed decision.',
        'To ENSURE every individual member with ASD will get the proper Assessment as well as the appropriate PROGRAM.'
      ]
    },
    {
      src: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Content/content_2.jpg`,
      header: 'As a promoter of awareness for Autism',
      points: [
        'To FACILITATE and COORDINATE the implementation of programmes.',
        'To MONITOR and EVALUATE the impact and effectiveness of each program as well as the progress of each individual member with ASD.',
        'To PROMOTE a better education system and facilitate an ongoing education.',
        'To PROVIDE appropriate training opportunities for the individual member.'
      ]
    },
    {
      src: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Content/content_3.jpg`,
      header: 'As an organization at the forefront of Autism advocacy',
      points: [
        'To COOPERATE and liaise with other International organization connected with Autism Spectrum Disorder so as to UPGRADE and IMPROVE our knowledge of Autism.',
        'Any collaboration or association with any organization outside Negara Brunei Darussalam can only be implemented after getting written approval from the Registrar of Society, Negara Brunei Darussalam.',
        'To EXCHANGE ideas, views and knowledge through seminars, conferences, workshops, lectures and etc.',
        'To HIGHLIGHT to the public the problems faced by individuals with Autism and to make the public appreciate and empathize with them more.'
      ]
    },
    {
      src: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Content/content_4.jpg`,
      header: 'As the center of research for Autism',
      points: [
        'To RAISE FUNDS for the Society to use for the welfare of individual members with ASD.',
        'To PUBLISH Papers, Newsletters, Journals, Magazines, and Articles on Autism in Brunei so as to make a constructive contribution to the world and community and to promote the advancement of Individual members with ASD.',
        'To be a CENTRE of ASD for: Knowledge, Information and system References, Education and Training, Employment Agency, Crisis Intervention and Support Group.'
      ]
    }
  ]

export { contentData }
