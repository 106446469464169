const env = process.env.VUE_APP_ENV
const carouselData = [
  { src: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Carousel/carousel_1.jpg` },
  { src: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Carousel/carousel_2.jpg` },
  { src: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Carousel/carousel_3.jpg` },
  { src: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Carousel/carousel_4.jpg` },
  { src: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Carousel/carousel_5.jpg` }
]

export { carouselData }
