const env = process.env.VUE_APP_ENV
const centres = [
  {
    img: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/centre1.jpeg`,
    name: 'SMARTER B.A.C.A Centre',
    text: 'The Brunei Autism Centre for Adults (B.A.C.A.) is a G.I.F.T. centre (Guidance, Intervention, Foundation and Traning) established in May 2008 and aimed to provide Competency Based Traning (CBT) Program for ALL IWA (individuals with autism) ages 12 years old and above.',
    full: [
      { html:
        `<p>
          The Brunei Autism Centre for Adults (B.A.C.A.) is a G.I.F.T. centre (Guidance, Intervention, Foundation and Traning) which was established in May 2008 and aimed to provide Competency Based Traning (CBT) Program for ALL IWA (individuals with autism) ages 12 years old and above to attain competency in the 7 independent living skills in the areas of Self-Development, Competency Based Training (CBT) and Creative Arts Programme. It was formed to continuously provide the Adults with a programme that would address their needs as they face the development stages of their life and achieve Independent Living with DIGNITY.
        </p>
        </br>`
      },
      { html:
        `<p>
          SMARTER Brunei’s SME consists of Bakery, Smarter Autism Shop through Shop Assistant, Bistro and Café. SMARTER Brunei’s SMEs objective is to have all SMARTER Adults with autism to be employed, to continue Generating more job Opportunities for Adults with Autism (AWA) with more income generating projects towards our sustainability and financial security. At the moment SMARTER Brunei has 16 adults with autism who are employed and are ready for full employment where they can work in shifts, taking turns between the Bakery, Shop Assistant and SMARTER Café where they earn a salary. Our employed Adults with Autism currently receive 50 cents for reporting to work, and those who continue to work, receive a rate of 25 cents per hour. All our SME utilizes “clock in, clock out” system, similar to other workplaces. In this way, they are learning about the real working environment and also independence. Sometimes, they may not feel like working, but they’re generally quite motivated to work hard to earn their own money. We also provide our AWA with a SMARTER bank account to teach them the value of saving money.
        </p>
        <p>
          Part of SMARTER Brunei SME as well is to train students who are under 18 years old for employment. It is called the SMARTER Brunei Apprenticeship Program; in which we have 6 trainees that are trained in different areas of SMARTER SME. Some of the students, specifically those who are in the high support category, do back office tasks like cutting containers, making envelopes, printing stickers/labels and sticking it to SMARTER Brunei products with the help of SMARTER Brunei’s low cost visual support.
        </p>`
      },
      { html:
        `<h3 class="mb-2 mt-10">
          SMARTER Brunei’s BJAK CAFE
        </h3>
        <p>
          It has helped to start Adults with Autism’s  journey to sustainability and employment, and provide them an opportunity to earn their own salary where they are involved in running the Bistro and Café. Thus, with this new branch, this Bistro and Café is the first ever Autism Café, which employs 100% individuals with autism to operate the establishment, such as in the preparation, providing service to customers, and maintaining the cleanliness and orderliness.
        </p>`,
        images: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bjak/bjak1.png`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bjak/bjak2.png`
        ]
      },
      { html:
        `<h3 class="mb-2 mt-10">
          SMARTER Washington Bakery
        </h3>
        <p>
          SMARTER Brunei Washington Bakery is a grant from the US Embassy established on November 8, 2014. Known as SMARTER Brunei’s starting journey to sustainability and employment. It is intended to empower individuals with autism to celebrate the communities that support them and to encourage equal opportunities for all regardless of ability. From Tiga Rasa Cookies, (Oatmeal, Choco chips, Milo cookies) we have now added our menu and ventured in baking buns in different fillings (chocolate, kaya, cheese, red bean) pandesal, chocolate crinkles, chocolate muffins and banana muffins.
        </p>
        <p>
          This is to train our Adults with autism in a bakery environment using standard baking materials and equipment in which they can also practise when they are at home. We have 8 adults with autism working in our Washington bakery at the moment.
        </p>`,
        images: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bakery/bakery1.png`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bakery/bakery2.png`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bakery/bakery3.png`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bakery/bakery4.png`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bakery/bakery5.png`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bakery/bakery6.png`
        ]
      },
      { html:
        `<h3 class="mb-2 mt-10">
          SMARTER B.A.C.A. Bistro Cafe
        </h3>
        <p>
          The Smarter Brunei Bistro and Café is part of SMARTER Brunei’s SME that trains Adults with Autism to be a Barista. After a successful collaboration with It’s a Grind in training 6 of our individual with autism in making coffee, SMARTER Brunei opened its own café from a grant from Brunei LNG which specialize in serving coffee from ASEAN countries. Under our SMARTER Brunei Bistro and Café is the SMARTER Kitchen that trains our Adult with Autism how to make food items like healthy nasi katok (air fried), sandwiches (cheese, tuna, chicken), shepherd’s pie, crackers and the new on in our menu to cater for the vegetarian community, our veggie pie. From the originally six trained baristas, we have now 12 trained baristas employed in our Bistro.
        </p>
        <p>
          From our ASEAN hot coffee choices, SMARTER Brunei Bistro and Café has now new sets of refreshing drinks available on our menu. We have added cold drinks using our own ASEAN coffee beans such as ice blended caramel mocha, ice blended mocha, iced mocha and iced cappuccino. We also ventured in offering chocolate drinks like hot choco, and iced chocolate. New on our menu as well are cold refreshing fruity drinks that are already available.
        </p>`,
        images: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bistro/bistro1.png`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bistro/bistro2.png`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bistro/bistro3.png`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bistro/bistro4.png`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/Bistro/bistro5.png`
        ]
      }
    ],
    location: 'Sg Hanching',
    lat: 4.964489228297071,
    lng: 114.97531793559854
  },
  {
    img: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/centre2.jpeg`,
    name: 'SMARTER E.D.G.E Centre',
    text: 'The Autism Spectrum Disorder (ASD) programme is geared towards INTER-DEPENDENT LIVING SKILLS with the core programme that revolves around Sensory Processing Intervention Strategies in order to acquire the 7 essential skills',
    full: [
      { html:
        `<p>
          “Autism Spectrum Disorder (ASD) Centre '' was officially formed on 13th January 2003.  SMARTER Brunei’s First ASD Center was placed in a one room borrowed at Jigsaw Primary School, Kampung Berakas, where the main focus of the programme at that time was early intervention. Now, we have our permanent place in a bungalow donated by the Ministry Of Health in Kampong Perpindahan Matamata for our 12 years and below. The ASD programme is geared towards INTER-DEPENDENT LIVING SKILLS with the core programme that revolves around Sensory Processing Intervention Strategies in order to acquire the 7 essential skills:
        </p>
        <ul class="mb-5">
          <li>Personal Management</li>
          <li>Home Management</li>
          <li>Leisure and Spiritual Management</li>
          <li>Academic Skills Management</li>
          <li>Vocational Management</li>
          <li>Employment Management</li>
          <li>Family Life Management</li>
        </ul>
        <p>
          SMARTER has developed its STEP program to meet the needs of those attending the centre, by using the “BEST PRACTICES” of all available programs from around the world as we know that there is NOT ONE SINGLE PROGRAM that is likely to work with all IWA. Therefore, SMARTER has developed its comprehensive Social,Training, Educational and Psychological (STEP) program by drawing on the BEST ASPECTS of all of the available programs on offer, ensuring that SMARTER programs are based on THE MOST CURRENT AND RELEVANT developmental, behavioural, educational, social and communication theory/research. It is a program that is  ECLECTIC, MULTI-SENSORIAL and HOLISTIC that provides Positive ATTITUDE, Appropriate SKILLS and Relevant KNOWLEDGE. It is HIGHLY STRUCTURED that provides INDIVIDUALISED PROGRAMME PLAN (IPP)(KPI) that is tailor made to their interests and needs, uses REAL LIFE ACTIVITIES in a REAL LIFE ENVIRONMENT that EMPHASISES and UTILISES the importance of the BRUNEI EXTENDED FAMILY STRUCTURE the philosophy of  MIB.
        </p>
        <h3 class="mb-2 mt-10">
          SMARTER E.D.G.E. KB Centre
        </h3>`,
        images: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Centres/centre1.png`
        ]
      }
    ],
    location: 'Brunei Darussalam',
    lat: 4.900364165273608,
    lng: 114.88724937825698,
    addon_maps: {
      lat: 4.587389988625501,
      lng: 114.19459948965024,
    }
  }
]

const centresDialog = [false, false, false]

export { centres, centresDialog }
