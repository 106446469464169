const env = process.env.VUE_APP_ENV
const editions = [
  {
    edition: '1st edition',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_1.jpg`,
    pdf_url: 'https://drive.google.com/file/d/1F3knzMrPaQU56vPhMqp0IFejEL6NWyVI/view?usp=sharing'
  },
  {
    edition: '2nd edition',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_2.jpg`,
    pdf_url: 'https://drive.google.com/file/d/1cQbdtRAdek0LGJP9vIXFM_Bjd-zyc8WZ/view?usp=sharing'
  },
  {
    edition: '3rd edition',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_3.jpg`,
    pdf_url: 'https://drive.google.com/file/d/1kLVDJx6TtHWVP-KVctXz8-lEFqpTu1mY/view?usp=sharing'
  },
  {
    edition: '4th edition',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_4.jpg`,
    pdf_url: 'https://drive.google.com/file/d/19qRtTkYXcY53mNKdm_Ja7GaR4iBLTlRd/view?usp=sharing'
  },
  {
    edition: '5th edition',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_5.jpg`,
    pdf_url: 'https://drive.google.com/file/d/10gNtbxMVQ4Zj7R9q14eJV-HWWOcwmJXi/view?usp=sharing'
  },
  {
    edition: '6th edition',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_6.jpg`,
    pdf_url: 'https://drive.google.com/file/d/1kO7qPEFwxvL-X5lGcWbcAVKMCF4OIgqJ/view?usp=sharing'
  },
  {
    edition: '7th edition',
    description: '<p>This edition of PITAH features Inclusive Development. It talks about of deepest concerns of parents of IWA (Individuals with Autism) that their children will be treated like lepers of the past, rejected as a problem by society.</p><p>They will not be allowed to go to school. “We do not have facilities to handle your child. Sorry. Maybe another school will take him..”. When he grows up into an adult, will employers simply reject them because, “ He needs too much supervision at work? I can’t run a business like this.”</p>',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_7.jpg`,
    pdf_url: 'https://drive.google.com/file/d/10-IJiwvrHHJDdwsyAT-40MA_wVWRSiW4/view?usp=sharing'
  },
  {
    edition: '8th edition',
    description: '<p>The PITAH publication has played a significant role in disseminating information to the public society. delivering news and events regarding SMARTER and particularly genuine stories from parent’s experiences with their child who have autism. In this issue, the publication came up with a cover that would significantly represent it’s continued survival despite its financial struggles.</p><p>SMARTER’s CEO only son, Farid Azeem Malai Hj Abdullah, was the main reason for SMARTER’s inception. Farid Azeem has been a part of the organization from the moment that it was just about to be written on papers. Without Farid Azeem, SMARTER Brunei would not have been in existence.</p>',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_8.jpg`,
    pdf_url: 'https://drive.google.com/file/d/1kmwiRq6Z2p-m6txgvKHgk_RsNgMsP7Dg/view?usp=sharing'
  },
  {
    edition: '9th edition',
    description: '<p>This edition of PITAH features 7 Individuals with Autism. They were opted to be called the magnificent 7. Namely Farid, Irfan, Matin, Qayyum, Adib, Udin and Irah. With all the hardwork and determination, these 7 were the front lines for the Management of Autism Related Issues in Traning, Education and Research (SMARTER) An organization for Individuals with Autism ran by the families and for the families, indeed one of its kind.</p><p>For 10 years the organization had taken all the intricate challenges and surprisingly still subsisting. Given all the achievements of the Organization for the past 10 years, the recognition from international bodies, the success of Oddy’s Quest and the apparent progress and development of our IWAs. From this point of time we greatly deem that “We are Reaching the ALITUDE” and the whole thing would not be likely existing if not because of the joint effort of this Magnificent 7.</p>',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_9.jpg`,
    pdf_url: ''
  },
  {
    edition: '10th edition',
    description: '<p>This edition of PITAH magazine describes SMARTER’s continuos effort to provide the BEST (Beneficial, Entrepreneurial, Self-sufficient, Talent Based) programme and services for Individuals with Autism to have the EDGE (Educate, Develop, Grow and Empower) to be independent, productive and useful members of our society. Going through each individual’s developmental milestone from childhood to Old age, we need to have the GOAL for their future and that GOAL in Generating Opportunities for Adult Life for Adults with Autism.</p><p>Part of this opportunity is to equip them with a vocational certification programme which we call Competency Based Training (CBT) Programme on 9 vocational courses namely; Shop Assistant, Office Assistant, Carpentry, Car Services, Electronics and Electricals, Metal Work, Baking and Computer Skills.</p><p>We have also started a Small Medium Enterprise of baking products which provides work experience and full employment for our adult with Autism. This will guarantee and ensure a Brighter future for our Adults with Autism to live with dignity and have a Quality of life.</p>',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_10.jpg`,
    pdf_url: 'https://drive.google.com/file/d/1MAIb5BNhtsoAWp_oSGjSjxNHLx0xCPyK/view?usp=sharing'
  },
  {
    edition: '11th edition',
    description: '<p>In this Edition of the PITAH Magazine; The Front Cover describes Hafiz, who is 19 years old. He came to SMARTER Brunei at the age of 18 in February 2013 and has since then progressed and improved in leaps and bounds.</p><p>His confidence and ability as well as his undeniable talent in singing and dancing proves to us that he is proud to be an individual with Autism.</p>',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_11.jpg`,
    pdf_url: ''
  },
  {
    edition: '12th edition',
    description: '<p>In this Edition of PITAH Magazine; The Front Cover shows his Majesty’s Kebawah Duli Yang Maha Mulia Paduka Seri Baginda Sultan Haji Hassanal Bolkiah Mu’izzaddin Waddaulah ibni Al-Marhum Sultan Haji Omar ‘Ali Saifuddien Sa’adul Khairi Waddien, Sultan dan Yang di-Pertuan Negara Brunei Darussalam, passion on helping and taking care of his people most especially the Individuals with Autism and other individuals with special needs.</p><p>It is shown here how he appreciates the harwork of the President, parents and therapist hardwork on developing the IWA’s of SMARTER Brunei.</p>',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_12.jpg`,
    pdf_url: ''
  },
  {
    edition: '13th edition',
    description: '<p>In this Edition of PITAH Maganize: The front cover shows Yang Teramat Mulia Paduka Seri Duli Pengiran Muda’ Abdul Malik ibni Kebawah Duli Yang Maha Mulia Paduka Seri Baginda Sultan Haji Hassanal Bolkiah Mu’izzaddin Waddaulah, D.K.M.B., P.H.B.S., gracing the Official Opening of the New SMARTER EDGE (Early Development Growth and Enabling) Centre at Mata Mata, Gadong Brunei Darussalam.</p><p> He was very happy to see the efforts made by the President, parents and therapist in achieving another milestone in helping the IWA’s of SMARTER Brunei.</p>',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_13.jpg`,
    pdf_url: 'https://drive.google.com/file/d/1PMShvaecRk_URn_QGqoyye1mN4Bzv_kk/view?usp=sharing'
  },
  {
    edition: '14th edition',
    description: '<p>In this Edition of PITAH Magazine: The front cover shows The President of the Republic of India, His Excellency Ram Nath Kovind together with the late President and CEO of SMARTER Brunei, Malai Hj Abdullah Bin Malai Hj Othman being presented with the fourth highest civilian award in Republic of India, The Padma Shri award.</p><p>The late President and CEO of SMARTER Brunei, Malai Hj Abdullah Bin Malai Hj Othman was conferred the prestigious Padma Shri Award in recognition of his work for Children and adults with Autism Spectrum Disorder (ASD) in Brunei Darussalam.</p>',
    coverImage: `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Pitah/pitah_14.jpg`,
    pdf_url: 'https://drive.google.com/file/d/1Kn1RhAND6mSodGcpFCpUSbDnOmwVItyZ/view?usp=sharing'
  }
]

export { editions }
