<template>
  <div class="footer pt-15 pb-5">
    <v-row 
      class="white--text subtitle-1 px-8 px-sm-15"
      justify="space-between"
    >
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <p class="font-weight-bold">
          Locations
        </p>
        <p>
          Click on the following links to view on Google maps
        </p>
        <template v-for="(location, index) in locations">
          <div :key="index">
            <a
              :href="`http://maps.google.com/?q=${location.address}`"
              target="_blank"
              class="white--text"
            >
              {{ location.name }}
            </a>
          </div>
        </template>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <p class="font-weight-bold">
          Sitemap
        </p>
        <p
          v-for="item in sitemap" 
          :key="item.name" 
        >
          <a 
            class="white--text"
            :href="item.link"
          >
            {{ item.name }}
          </a>
        </p>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <p class="font-weight-bold">
          Contact us
        </p>
        <p>
          SMARTER E.D.G.E Centre - +673 2420225<br>
          SMARTER B.A.C.A Centre - +673 2654812<br>
          SMARTER Brunei Hotline - +673 7172991<br>
          admin@smarterbrunei
        </p>
        <v-row class="pl-1 pb-6">
          <v-btn 
            icon 
            v-for="link in links" 
            :key="link.href" 
            :href="link.href" 
            target="_blank"
          >
            <v-icon color="white">
              {{ link.icon }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="3"
      >
        <p class="font-weight-bold">
          Working hours
        </p>
        <p>Monday - Thursday: 8am - 5pm</p>
        <p>Saturday: 8am - 5pm</p>
        <p>Friday & Sunday: Closed</p>
      </v-col>
    </v-row>
    <p class="text-center white--text mt-10">
      Copyright &copy; {{ new Date().getFullYear() }}. Powered by <a class="white--text font-weight-bold" @click="openSynapse">SynapseBN</a>
    </p>
  </div>
</template>

<script>
import { navbar } from '../data/navbar'
import { links } from '../data/footer'

export default {
  data () {
    return {
      links: links,
      sitemap: navbar,
      locations: [
        { name: 'SMARTER EDGE Centre', address: 'Spg 74-59 Jalan Seri Setia Satu Kampong Perpindahan, Mata-mata Gadong, BSB, BE1718, Brunei Darussalam' },
        { name: 'SMARTER BACA Centre', address: 'BACA GIFT Centre, Berakas B Kg Sungai Hanching, BC2115, Brunei Darussalam' },
        { name: 'SMARTER EDGE Centre (KB)', address: 'No. 9 Jalan Mc Kerron, Kuala Belait, KA1131, Brunei Darussalam' },
        { name: 'BJAK Cafe', address: 'Anggerek Desa Swimming Pool, Bandar Seri Begawan BB3713, Brunei Darussalam' }
      ]
    }
  },
  methods : {
    openSynapse () {
      window.open('https://synapsebn.com', '_blank')
    }
  }
}
</script>

<style scoped lang="scss">
.footer {
  background-color: #222;
}

a:hover {
  color: #006993 !important;
}
</style>
