import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Backstory from '../views/Backstory.vue'
import Epitah from '../views/E-pitah.vue'
import Projects from '../views/Projects.vue'
import News from '../views/News.vue'
import SingleNews from '../views/SingleNews.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/backstory',
    name: 'backstory',
    component: Backstory
  },
  {
    path: '/e-pitah',
    name: 'e-pitah',
    component: Epitah
  },
  {
    path: '/projects',
    name: 'projects',
    component: Projects
  },
  {
    path: '/news',
    name: 'news',
    component: News
  },
  {
    path: '/news/:id',
    name: 'SingleNews',
    component: SingleNews,
    props: true
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

export default router
