<template>
  <div class="banner-bg">
    <h1 class="primary--text pt-15">
      Parents & Students Testimonials
    </h1>
    <div 
      :class="{ 
        scroller: $vuetify.breakpoint.lgAndUp, 
        scrollerMd: $vuetify.breakpoint.md, 
        scrollerSm: $vuetify.breakpoint.smAndDown 
      }"
      class="mb-5 pb-12"
      v-scroll-x
    >
      <v-card 
        v-for="(card, index) in studentCards"
        :key="index"
        class="pa-5 d-flex flex-column justify-space-between snap-start"
        tile
      >
        <p class="pa-0">
          {{ card.content }}
        </p>
        <v-card-actions class="pa-1">
          <v-avatar
            size="50"
            class="mr-3"
            :color="randomColor"
          >
            <img 
              v-if="card.img != ''"
              :src="card.img" 
              @error="imageError"
            >
          </v-avatar>
          {{ card.title }}
          <v-spacer></v-spacer>
          <v-dialog
            v-model="studentDialog[index]"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                text
                color="primary"
                v-on="on"
                class="v-buttons"
              >
                Read More
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <v-avatar
                  size="50"
                  class="mr-3"
                  :color="randomColor"
                >
                  <img 
                    v-if="card.img != ''"
                    :src="card.img" 
                    @error="imageError"
                  >
                </v-avatar>
                {{ card.title }}
                <v-spacer></v-spacer>

                <v-btn
                  icon
                  @click="closeStudentDialog(index)"
                  color="primary"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <p v-html="card.full"></p>
              </v-card-text>
              <v-row class="px-5">
                <v-col
                  cols="12"
                  :md="card.images.length % 2 == 0 ? 6 : 4"
                  v-for="image in card.images"
                  :key="image"
                  class="ma-auto"
                >
                  <Picture :imgsrc="image" height="auto" maxHeight="300" contain></Picture>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </div>
    <h1 class="primary--text pt-md-15">
      Teachers & Volunteers Testimonials
    </h1>
    <div 
      :class="{ 
        scroller: $vuetify.breakpoint.lgAndUp, 
        scrollerMd: $vuetify.breakpoint.md, 
        scrollerSm: $vuetify.breakpoint.smAndDown 
      }"
      class="pb-15"
      v-scroll-x
    >
      <v-card 
        v-for="(card, index) in parentCards"
        :key="index"
        class="pa-5 d-flex flex-column justify-space-between snap-start"
        tile
      >
        <p class="pa-0">
          {{ card.content }}
        </p>
        <v-card-actions class="pa-1">
          <v-avatar
            size="50"
            class="mr-3"
            :color="randomColor"
          >
            <img 
              v-if="card.img !== ''"
              :src="card.img" 
              @error="imageError"
            >
          </v-avatar>
          {{ card.title }}
          <v-spacer></v-spacer>

          <v-dialog
            v-model="parentDialog[index]"
          >
            <template v-slot:activator="{ on }">
              <v-btn
                text
                color="primary"
                v-on="on"
                class="v-buttons"
              >
                Read More
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <v-avatar
                  size="50"
                  class="mr-3"
                  :color="randomColor"
                >
                  <img 
                    v-if="card.img != ''"
                    :src="card.img" 
                    @error="imageError"
                  >
                </v-avatar>
                {{ card.title }}
                <v-spacer></v-spacer>

                <v-btn
                  icon
                  @click="closeParentDialog(index)"
                  color="primary"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <p v-html="card.full"></p>
              </v-card-text>
              <v-row class="px-5">
                <v-col
                  cols="12"
                  :md="card.images.length % 2 == 0 ? 6 : 4"
                  v-for="image in card.images"
                  :key="image"
                  class="ma-auto"
                >
                  <Picture :imgsrc="image" height="auto" maxHeight="300" contain></Picture>
                </v-col>
              </v-row>
            </v-card>
          </v-dialog>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>

<script>
import { studentCards, studentDialog, parentCards, parentDialog } from '../data/testimonials'
import Picture from './Picture.vue'

export default {
  components: {
    Picture
  },
  data () {
    return {
      studentDialog: studentDialog,
      parentDialog: parentDialog,
      studentCards: studentCards,
      parentCards: parentCards,
      colors: [ "primary", "secondary", "accent" ],
      randomColor : ''
    }
  },
  methods: {
    closeStudentDialog (index) {
      this.$set(this.studentDialog, index, false)
    },
    closeParentDialog (index) {
      this.$set(this.parentDialog, index, false)
    },
    imageError (event) {
      event.target.src = `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${this.$env}/loading.gif`
      event.target.style.width = 'auto'
    }
  },
  created () {
    this.randomColor = this.colors[Math.floor(Math.random() *  3)]
  }
}
</script>

<style scoped lang="scss">
  @mixin grid-overflow {
    display: grid;
    grid-auto-flow: column;
    list-style: none;
    overflow-x: auto;
    overscroll-behavior-inline: contain;
    scroll-snap-type: inline mandatory;
  }

  $spacer: 2rem;
  $spacer-mobile: 1rem;

  .v-buttons:focus::before {
    opacity: 0 !important;
  }

  .scroller {
    @include grid-overflow;
    grid-auto-columns: 30%;
    gap: $spacer;
    padding: 0 $spacer $spacer;
    scroll-padding-inline: $spacer;
    overflow-x: scroll;
  }

  ::-webkit-scrollbar {
    height: 7px !important;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #006993;
    border-radius: 5px;
  }

  .scrollerMd {
    @include grid-overflow;
    grid-auto-columns: 42%;
    gap: $spacer-mobile;
    padding: 0 $spacer-mobile $spacer-mobile;
    scroll-padding-inline: $spacer-mobile;
  }

  .scrollerSm {
    @include grid-overflow;
    grid-auto-columns: 93%;
    gap: $spacer-mobile;
    padding: $spacer-mobile;
    scroll-padding-inline: $spacer-mobile;
  }

  .snap-start {
    scroll-snap-align: start;
  }

  @media only screen and (max-width: 960px) {
    ::-webkit-scrollbar {
      height: 0px !important;
    }

    h1 {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
</style>
