<template>
  <div class="content">
    <div class="text-center banner-bg">
      <h1 class="primary--text">
        Who is Smarter Brunei?
      </h1>
      <p v-scroll-x>
        SMARTER Brunei is a SANCTUARY, a G.I.F.T. center where they can be themselves, where we provide a S.A.F.E. environment for our individuals with Autism to have an E.D.G.E. to their P.A.T.H.S. of L.I.F.E. It is a GARDEN of E.D.E.N. and I made a P.L.E.D.G.E. to give our PWA a S.M.I.L.E. with DIGNITY.
      </p>
      <p v-scroll-x>
        SMARTER was established 9th September 2001, founded by parents and headed by Padma Shri Malai Hj Abdullah bin Malai Hj Othman.
      </p>
      <v-btn text color="primary" to="/backstory">Read more</v-btn>
      <v-row class="mission-vision" no-gutters v-scroll-x>
        <v-col>
          <v-card min-height="275" class="rounded-r-0">
            <h1 class="primary--text pt-5">
              Our Mission
            </h1>
            <p class="px-10 pb-10 text-center">
              To give an EDGE: Education, Development, Growth, and Empowering Individuals With Autism so they will be CHIPS : Contributing, Holistic, Interdependent, Productive, and Successful members of the society
            </p>
          </v-card>
        </v-col>
        <v-col>
          <v-card min-height="275" class="rounded-l-0">
            <h1 class="primary--text pt-5">
              Our Vision
            </h1>
            <p class="px-10 pb-10 text-center">
              To have an Autism Friendly Nation that enables our children to adapt and to have a future with a SMILE: Sociable, Meaningful, Interdependent Life and Employment.
            </p>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <div class="checkered-bg pa-8 pa-sm-15">
      <h1 class="white--text mt-15">
        Our Aims & Objectives
      </h1>
      <p v-scroll-x class="text-center mb-8 white--text">
        These aims and objectives shall be coming out accordingly from time to time by “the Society” and its members.
      </p>
      <v-row>
        <v-col cols="12" md="6">
          <v-expansion-panels focusable multiple v-scroll-x :value="[Math.floor(Math.random() * 2)]">
            <v-expansion-panel
              v-for="(item, i) in items.slice(0, 2)"
              :key="i"
            >
              <v-expansion-panel-header class="primary--text">{{ item.header }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <Picture :imgsrc="item.src" class="mb-5"></Picture>
                <p
                  v-for="(point, index) in item.points"
                  :key="index"
                  class="px-5"
                >{{ point }}</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <v-col cols="12" md="6">
          <v-expansion-panels focusable multiple v-scroll-x :value="[Math.floor(Math.random() * 2)]">
            <v-expansion-panel
              v-for="(item, i) in items.slice(2, 4)"
              :key="i"
            >
              <v-expansion-panel-header class="primary--text">{{ item.header }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <Picture :imgsrc="item.src" class="mb-5"></Picture>
                <p
                  v-for="(point, index) in item.points"
                  :key="index"
                  class="px-5"
                >{{ point }}</p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <p v-scroll-x class="text-center mt-8 mb-0 white--text px-sm-15">
        Above all, close collaboration and working together with the parents, families, therapists and other professionals will help the children develop to their fullest potential thus inspiring SMARTER's Motto of <br><i>“Optimum Stimulation for Maximum Development.”</i>
      </p>
    </div>
  </div>
</template>

<script>
import { contentData } from '../data/content'
import Picture from './Picture.vue'

export default {
  components: {
    Picture
  },
  data () {
    return {
      items: contentData
    }
  }
}

</script>

<style scoped lang="scss">
  .banner-bg {
    padding: 50px 200px 0 200px !important;
  }

  .mission-vision {
    position: relative;
    top: 75px;
  }

  .v-carousel {
    position: relative;
  }

  ::v-deep .v-expansion-panel-content__wrap {
    padding: 0px !important;
  }

  @media screen and (max-width: 960px) {
    .banner-bg {
      padding: 60px 30px 30px 30px !important;
    }
  }
</style>
