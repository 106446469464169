var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner-bg"},[_c('h1',{staticClass:"primary--text pt-15"},[_vm._v(" Parents & Students Testimonials ")]),_c('div',{directives:[{name:"scroll-x",rawName:"v-scroll-x"}],staticClass:"mb-5 pb-12",class:{ 
      scroller: _vm.$vuetify.breakpoint.lgAndUp, 
      scrollerMd: _vm.$vuetify.breakpoint.md, 
      scrollerSm: _vm.$vuetify.breakpoint.smAndDown 
    }},_vm._l((_vm.studentCards),function(card,index){return _c('v-card',{key:index,staticClass:"pa-5 d-flex flex-column justify-space-between snap-start",attrs:{"tile":""}},[_c('p',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(card.content)+" ")]),_c('v-card-actions',{staticClass:"pa-1"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"50","color":_vm.randomColor}},[(card.img != '')?_c('img',{attrs:{"src":card.img},on:{"error":_vm.imageError}}):_vm._e()]),_vm._v(" "+_vm._s(card.title)+" "),_c('v-spacer'),_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"v-buttons",attrs:{"text":"","color":"primary"}},on),[_vm._v(" Read More ")])]}}],null,true),model:{value:(_vm.studentDialog[index]),callback:function ($$v) {_vm.$set(_vm.studentDialog, index, $$v)},expression:"studentDialog[index]"}},[_c('v-card',[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"50","color":_vm.randomColor}},[(card.img != '')?_c('img',{attrs:{"src":card.img},on:{"error":_vm.imageError}}):_vm._e()]),_vm._v(" "+_vm._s(card.title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.closeStudentDialog(index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('p',{domProps:{"innerHTML":_vm._s(card.full)}})]),_c('v-row',{staticClass:"px-5"},_vm._l((card.images),function(image){return _c('v-col',{key:image,staticClass:"ma-auto",attrs:{"cols":"12","md":card.images.length % 2 == 0 ? 6 : 4}},[_c('Picture',{attrs:{"imgsrc":image,"height":"auto","maxHeight":"300","contain":""}})],1)}),1)],1)],1)],1)],1)}),1),_c('h1',{staticClass:"primary--text pt-md-15"},[_vm._v(" Teachers & Volunteers Testimonials ")]),_c('div',{directives:[{name:"scroll-x",rawName:"v-scroll-x"}],staticClass:"pb-15",class:{ 
      scroller: _vm.$vuetify.breakpoint.lgAndUp, 
      scrollerMd: _vm.$vuetify.breakpoint.md, 
      scrollerSm: _vm.$vuetify.breakpoint.smAndDown 
    }},_vm._l((_vm.parentCards),function(card,index){return _c('v-card',{key:index,staticClass:"pa-5 d-flex flex-column justify-space-between snap-start",attrs:{"tile":""}},[_c('p',{staticClass:"pa-0"},[_vm._v(" "+_vm._s(card.content)+" ")]),_c('v-card-actions',{staticClass:"pa-1"},[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"50","color":_vm.randomColor}},[(card.img !== '')?_c('img',{attrs:{"src":card.img},on:{"error":_vm.imageError}}):_vm._e()]),_vm._v(" "+_vm._s(card.title)+" "),_c('v-spacer'),_c('v-dialog',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({staticClass:"v-buttons",attrs:{"text":"","color":"primary"}},on),[_vm._v(" Read More ")])]}}],null,true),model:{value:(_vm.parentDialog[index]),callback:function ($$v) {_vm.$set(_vm.parentDialog, index, $$v)},expression:"parentDialog[index]"}},[_c('v-card',[_c('v-card-title',[_c('v-avatar',{staticClass:"mr-3",attrs:{"size":"50","color":_vm.randomColor}},[(card.img != '')?_c('img',{attrs:{"src":card.img},on:{"error":_vm.imageError}}):_vm._e()]),_vm._v(" "+_vm._s(card.title)+" "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.closeParentDialog(index)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('p',{domProps:{"innerHTML":_vm._s(card.full)}})]),_c('v-row',{staticClass:"px-5"},_vm._l((card.images),function(image){return _c('v-col',{key:image,staticClass:"ma-auto",attrs:{"cols":"12","md":card.images.length % 2 == 0 ? 6 : 4}},[_c('Picture',{attrs:{"imgsrc":image,"height":"auto","maxHeight":"300","contain":""}})],1)}),1)],1)],1)],1)],1)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }