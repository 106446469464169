const env = process.env.VUE_APP_ENV
const projects = [
  {
    year: 2020,
    events : [
      { 
        title: 'SMARTER Brunei Family Fun Day 2020', 
        imgs: [ 
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/FamilyFunDay/family1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/FamilyFunDay/family2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/FamilyFunDay/family3.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/FamilyFunDay/family4.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/FamilyFunDay/family5.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/FamilyFunDay/family6.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/FamilyFunDay/family7.jpeg`
        ] 
      },
      { 
        title: 'International Day of persons with Disability', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/InternationalDay/disability1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/InternationalDay/disability2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/InternationalDay/disability3.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/InternationalDay/disability4.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/InternationalDay/disability5.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/InternationalDay/disability6.jpeg`
        ] 
      },
      { 
        title: 'Charity Cooking Demonstration', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/CharityCookingDemonstration/cooking1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/CharityCookingDemonstration/cooking2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/CharityCookingDemonstration/cooking3.jpeg`
        ] 
      },
      { 
        title: 'SMARTER Brunei Anniversary 2020', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/SmarterAnniversary/anniversary1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/SmarterAnniversary/anniversary2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/SmarterAnniversary/anniversary3.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/SmarterAnniversary/anniversary4.jpeg`
        ] 
      },
      { 
        title: 'Mother and Child Awareness 2020', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/Mother&ChildAwareness/awareness1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/Mother&ChildAwareness/awareness2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/Mother&ChildAwareness/awareness3.jpeg`
        ] 
      },
      { 
        title: 'Turkish Ambassador and Ms. Cecillia handing over donations to SMARTER Brunei',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/PollutionAwareness/pollution1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/PollutionAwareness/pollution2.jpeg`
        ] 
      },
      { 
        title: 'His Excellency Ajaneesh Kumar High Commissioner of India visit and kind donation to SMARTER Brunei',  
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2020/CommissionerIndiaVisit/visit1.jpeg`
        ] 
      }
    ]
  },
  {
    year: 2021,
    events: [
      { 
        title: 'Artwork Shop at Jo Art Gallery', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ArtWorkshop/artwshop1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ArtWorkshop/artwshop2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ArtWorkshop/artwshop3.jpeg`
        ]
      },
      { 
        title: 'Charity Art Exhibition in conjunction with the 42nd Anniversary of the victory of the islamic revolution of Iran', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ArtExhibition/exhibition1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ArtExhibition/exhibition2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ArtExhibition/exhibition3.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ArtExhibition/exhibition4.jpeg`
        ]
      },
      { 
        title: 'Jab Gym and Corporate Sponsors Donates to SMARTER Brunei', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CorporateSponsors/sponsor1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CorporateSponsors/sponsor2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CorporateSponsors/sponsor3.jpeg`
        ]
      },
      { 
        title: 'Charity Art work handing over of certificates', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CharityArt/art1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CharityArt/art2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CharityArt/art3.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CharityArt/art4.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CharityArt/art5.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CharityArt/art6.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CharityArt/art7.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CharityArt/art8.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/CharityArt/art9.jpeg`
        ]
      },
      { 
        title: 'SMARTER Brunei field trip to Batang Duri', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/FieldTrip/trip1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/FieldTrip/trip2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/FieldTrip/trip3.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/FieldTrip/trip4.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/FieldTrip/trip5.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/FieldTrip/trip6.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/FieldTrip/trip7.jpeg`
        ]
      },
      { 
        title: 'SMARTER Brunei celebrates National Children’s day', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ChildrensDay/children1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ChildrensDay/children2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ChildrensDay/children3.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ChildrensDay/children4.jpeg`
        ]
      },
      { 
        title: 'SMARTER Brunei celebrates World Autism Awareness Day 2021', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/AutismAwareness/autism1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/AutismAwareness/autism2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/AutismAwareness/autism3.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/AutismAwareness/autism4.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/AutismAwareness/autism5.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/AutismAwareness/autism6.jpeg`
        ]
      },
      { 
        title: 'Reactivation of SMARTER Home Program (COVID-19 2nd Outbreak)', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/HomeProgram/home1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/HomeProgram/home2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/HomeProgram/home3.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/HomeProgram/home4.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/HomeProgram/home5.jpeg`
        ]
      },
      { 
        title: 'SMARTER Brunei BJAK Cafe Grand Opening', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/BjakOpening/bjak1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/BjakOpening/bjak2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/BjakOpening/bjak3.jpeg`
        ]
      },
      { 
        title: 'SMARTER Brunei Community Adaptation Program “ Going to Anggerek Desa Swimming Pool”', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/AnggerekDesa/swimming1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/AnggerekDesa/swimming2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/AnggerekDesa/swimming3.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/AnggerekDesa/swimming4.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/AnggerekDesa/swimming5.jpeg`
        ]
      },
      { 
        title: 'Jerudong International School Donates to SMARTER Brunei', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/JISDonation/jis1.jpeg`
        ]
      },
      { 
        title: 'SMARTER Brunei Student Vaccination Drive', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/Vaccination/vaccination1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/Vaccination/vaccination2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/Vaccination/vaccination3.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/Vaccination/vaccination4.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/Vaccination/vaccination5.jpeg`
        ]
      },
      { 
        title: 'ADF', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ADF/adf1.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ADF/adf2.jpeg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2021/ADF/adf3.jpeg`
        ]
      }
    ]
  },
  {
    year: 2022,
    events : [
      { 
        title: 'SMARTER Brunei Charity Carnival 2022',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/CharityCarnival/charity1.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/CharityCarnival/charity2.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/CharityCarnival/charity3.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/CharityCarnival/charity4.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/CharityCarnival/charity5.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/CharityCarnival/charity6.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/CharityCarnival/charity7.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/CharityCarnival/charity8.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/CharityCarnival/charity9.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/CharityCarnival/charity10.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/CharityCarnival/charity11.jpg`,
        ]
      },
      { 
        title: 'GoMamam handover Donation to SMARTER Brunei',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam1.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam2.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam3.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam4.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam5.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam6.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam7.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam8.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam9.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam10.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam11.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam12.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam13.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/GoMamamDonation/gomamam14.jpg`,
        ]
      },
      { 
        title: 'World Autism Awareness Day 2022',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/WAAD/waad1.jpg`
        ]
      },
      { 
        title: 'Samima Sdn Bhd Donates School supplies to SMARTER Brunei',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SamimaDonation/samima.jpg`
        ]
      },
      { 
        title: 'Philippine Embassy holds charity for SMARTER Brunei and Pusat Ehsan',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/PHEmbassy/phembassy1.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/PHEmbassy/phembassy2.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/PHEmbassy/phembassy3.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/PHEmbassy/phembassy4.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/PHEmbassy/phembassy5.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/PHEmbassy/phembassy6.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/PHEmbassy/phembassy7.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/PHEmbassy/phembassy8.jpg`,
        ]
      },
      { 
        title: 'High Commissioner of India and YMRM Group of companies visits SMARTER E.D.G.E. Centre',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/IndiaYMRM/ymrm1.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/IndiaYMRM/ymrm2.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/IndiaYMRM/ymrm3.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/IndiaYMRM/ymrm4.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/IndiaYMRM/ymrm5.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/IndiaYMRM/ymrm6.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/IndiaYMRM/ymrm7.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/IndiaYMRM/ymrm8.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/IndiaYMRM/ymrm9.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/IndiaYMRM/ymrm10.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/IndiaYMRM/ymrm11.jpg`,
        ]
      },
      { 
        title: 'Yesfull Care Centre donates to SMARTER Brunei', 
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Yesfull/yesfull1.jpg`
        ]
      },
      {
        title: 'His Excellency and spouse of Timor Leste, and @bruneifoodawards visit and donates to SMARTER Brunei',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BFA/bfa1.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BFA/bfa2.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BFA/bfa3.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BFA/bfa4.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BFA/bfa5.jpg`,
        ]
      },
      {
        title: 'SMARTER Brunei conducts staff training workshop',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff1.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff2.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff3.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff4.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff5.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff6.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff7.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff8.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff9.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff10.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff11.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff12.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/Workshop/staff13.jpg`,

        ]
      },
      {
        title: 'SMARTER Brunei welcomes donation from BSP',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BSP/bsp1.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BSP/bsp2.jpg`,
        ]
      },
      {
        title: 'Singapore Airlines visits SMARTER Brunei',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline1.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline2.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline3.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline4.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline5.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline6.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline7.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline8.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline9.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline10.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline11.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/SingaporeAirline/airline12.jpg`,
        ]
      },
      {
        title: 'Burger King donates to SMARTER Brunei',
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BurgerKing/bk1.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BurgerKing/bk2.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BurgerKing/bk3.jpg`,
        ]
      },
      {
        title: "BLNG donates 3 month's worth of ART Kits to SMARTER Brunei",
        imgs: [
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BLNG/blng1.jpg`,
          `https://smarter-brunei.sgp1.cdn.digitaloceanspaces.com/${env}/Projects/2022/BLNG/blng2.jpg`,
        ]
      }
    ]
  }
]

export { projects }
